import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

// Component Imports
import SEO from '../components/seo';
import Container from '../components/container';
import { Section } from '../components/page-elements';
import CenteredHeaderBlock from '../components/centeredHeaderBlock';
import { D3, P1 } from '../theme/typography';
import Spacer from '../components/spacer';
import BottomCut from '../components/bottomCut';

//Styled Components
const PrivacyPolicyContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 700px;
  margin: 0 auto;
`;

const PolicyHeading = styled(D3)`
  color: var(--blue1);
  position: relative;
  margin-bottom: 16px;
  text-transform: uppercase;
  span {
    color: var(--accent);
    margin-right: 16px;

    @media (min-width: 800px) {
      position: absolute;
      left: -40px;
      margin-right: 10;
    }
  }
`;

const PolicyText = styled(P1)`
  color: var(--blue1);
  margin-bottom: 32px;
  ul > li {
    list-style-type: disc;
    list-style-position: inside;
    ::marker {
      color: var(--accent);
    }
  }
  strong {
    color: var(--blue2);
    font-family: Poppins;
    font-weight: 900;
  }

  br {
    display: block;
    content: '';
  }

  a {
    text-decoration: underline var(--blue5);
    text-decoration-line: underline;
    -webkit-text-decoration-line: underline;

    &:hover {
      text-decoration-color: var(--accent);
    }
  }
`;

const PrivacyPolicyPage = () => {
  return (
    <>
      <SEO title='Privacy Policy' />
      <Helmet>
        <body className='light-nav' />
      </Helmet>
      <Section dark cuts={'bottomCut'}>
        <Container>
          <CenteredHeaderBlock
            data={{
              preHeader: 'Privacy Policy',
              title: 'The Nitty Gritty',
              body: 'How we handle your data. If you have any questions, get in touch at info@rubberduck.ie',
            }}
          />
        </Container>
        <BottomCut light />
      </Section>
      <Section light cuts={'topAndBottomCut'}>
        <Container>
          <PrivacyPolicyContainer>
            <PolicyHeading>
              <span>1.</span>Introduction
            </PolicyHeading>
            <PolicyText>
              This Privacy Policy outlines how www.rubberduck.ie ("we," "our,"
              or "us") collects, uses, shares, and protects your personal
              information. By using our website, you agree to the terms of this
              Privacy Policy.
            </PolicyText>
            <PolicyHeading>
              <span>2.</span>Information We Collect
            </PolicyHeading>
            <PolicyText>
              <strong>Personal Information:</strong>
              <br /> When users submit an optional contact form, we collect
              first names, last names, phone numbers, and email addresses.
              <br />
              <Spacer size={16} />
              <strong>Standard Analytics Data:</strong>
              <br /> We utilize Google Analytics to collect standard internet
              log information and details of visitor behavior patterns.
            </PolicyText>
            <PolicyHeading>
              <span>3.</span>Use of Your Information
            </PolicyHeading>
            <PolicyText>
              We use the collected information for the following purposes:{' '}
              <ul>
                <li>
                  To respond to inquiries submitted through the contact form.
                </li>
                <li> To analyze website traffic and improve our services.</li>
              </ul>
            </PolicyText>
            <PolicyHeading>
              <span>4.</span>Sharing Your Information
            </PolicyHeading>
            <PolicyText>
              We do not sell, trade, or otherwise transfer your personal
              information to outside parties. However, we may share information
              with trusted third parties who assist us in operating our website
              or servicing you.
            </PolicyText>
            <PolicyHeading>
              <span>5.</span>Google Analytics
            </PolicyHeading>
            <PolicyText>
              Our website uses Google Analytics, a web analytics service
              provided by Google, Inc. Google Analytics uses cookies to analyze
              how users use the site. For more information on Google Analytics,
              please refer to Google's Privacy Policy:{' '}
              <a
                href='https://policies.google.com/'
                target='_blank'
                rel='noreferrer noopener'
              >
                Google Privacy & Terms.
              </a>
            </PolicyText>
            <PolicyHeading>
              <span>6.</span>GDPR Compliance
            </PolicyHeading>
            <PolicyText>
              For users in the European Union (EU), we acknowledge and comply
              with the General Data Protection Regulation (GDPR). You have the
              right to access, correct, or delete your personal information. To
              exercise these rights, please contact us using the details
              provided below.
            </PolicyText>
            <PolicyHeading>
              <span>7.</span>Security
            </PolicyHeading>
            <PolicyText>
              We are committed to ensuring that your information is secure. We
              have implemented suitable physical, electronic, and managerial
              procedures to safeguard and secure the information we collect.
            </PolicyText>
            <PolicyHeading>
              <span>8.</span>Your Rights
            </PolicyHeading>
            <PolicyText>
              You have the right to access, correct, or delete your personal
              information. To exercise these rights, please contact us using the
              details provided below.
            </PolicyText>
            <PolicyHeading>
              <span>9.</span>Cookie Policy
            </PolicyHeading>
            <PolicyText>
              Please refer to our <a href={'/cookies-policy'}>Cookie Policy</a>{' '}
              to understand how we use cookies on our website.
            </PolicyText>
            <PolicyHeading>
              <span>10.</span>Contact Us
            </PolicyHeading>
            <PolicyText>
              If you have any questions or concerns regarding this Privacy
              Policy, please contact us at:{' '}
              <a href='mailto:info@rubberduck.ie'>info@rubberduck.ie</a>
            </PolicyText>
            <PolicyHeading>
              <span>11.</span>Changes to the Privacy Policy
            </PolicyHeading>
            <PolicyText>
              We reserve the right to update or modify this Privacy Policy. Any
              changes will be effective immediately upon posting on our website.
            </PolicyText>
          </PrivacyPolicyContainer>
        </Container>
        <BottomCut dark />
      </Section>
    </>
  );
};

export default PrivacyPolicyPage;
